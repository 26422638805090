import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledSlide = styled.div`
  padding: 8rem 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 8rem 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }
  }
`


const AvisoPrivacidadPage = () => (
  <Layout>
    <SEO title="Aviso de privacidad" />
    <StyledSlide>
        <p>
Inicio de vigencia: 1 de junio 2020<br />
<br />
<b>KKO, S.A.P.I. de C.V.</b>, con domicilio en Jesus Garcia 1542, Col. Villaseñor, 44600, Guadalajara, Jalisco, es el responsable del tratamiento de sus datos personales, del uso que se da a los mismos y de su protección.<br />
El presente Aviso detalla la forma en que <b>KKO, S.A.P.I. de C.V.</b>, sus afiliadas y subsidiarias  (todos los anteriores, conjuntamente "KKO" o "nosotros") utilizan sus datos personales y sobre los derechos que usted tiene. Por favor tómese un minuto para revisar esta información.<br />
<br />
<b>¿Qué datos personales que obtenemos de usted?</b><br />
Para las finalidades establecidas en este Aviso de Privacidad, KKO obtendrá de los clientes que utilicen nuestro Sitio Web y de aquellos que voluntariamente nos los proporcionen en nuestras tiendas o sitios web los siguientes datos personales:<br />
&emsp;a. <b>Datos de identificación</b>: Información que permite diferenciarlo de otros individuos. Estos datos pueden incluir: nombre y apellidos, fecha de nacimiento y género.<br />
&emsp;b. <b>Datos de contacto</b>: Información que permite mantenernos en contacto con usted. Estos datos pueden incluir: correo electrónico y código postal.<br />
&emsp;c. <b>Registros electrónicos</b>: Esta información incluye ciertos datos de usted o de su dispositivo y que son obtenidos cuando usted accede a nuestras Propiedades Digitales y (ii); los registros de imágenes y/o voz obtenidos a través de sistemas de video-vigilancia, que son obtenidos cuando usted visita nuestras instalaciones.<br />
&emsp;d. <b>Datos financieros o patrimoniales</b>: Estos datos son recabados cuando quiera hacer uso de nuestros servicios y se registre con nosotros, incluidos: ingresos y egresos, rentas, bienes muebles e inmuebles, reporte de buró de crédito, historial crediticio.<br />
<br />
<br />
KKO obtiene datos personales de los clientes cuando estos visitan nuestro Sitio Web y voluntariamente completa nuestro formulario de contacto y acepta recibir información sobre nuestras ofertas y novedades (que son enviadas a través de nuestro Newsletter).  Adicionalmente, podemos obtener sus datos personalmente en nuestras tiendas, cuando usted nos solicita que lo registremos a nuestros servicios de Boletín Informativo, usualmente mediante el llenado del formulario correspondiente.<br />
<br />
<b>No obtenemos datos sensibles.</b><br />
KKO no obtiene proactivamente de usted datos personales que pudieran ser considerados como sensibles, tales como religión, estado de salud, preferencia sexual, opiniones políticas y datos semejantes. Le rogamos que no nos haga llegar datos de esta naturaleza. Aun en el caso de que KKO reciba estos datos de usted, cuando usted voluntariamente los haya proporcionado (v.g. a través de un correo electrónico), nosotros no utilizaremos estos datos para finalidad alguna.<br />
<br />
<br />
<b>Obtención de datos a través de Cookies y otras tecnologías</b><br />
Nuestro Sitio Web utiliza "<b>Cookies</b>". Las cookies son pequeños archivos de datos que se almacenan en el disco duro de su equipo de cómputo o del dispositivo de comunicación electrónica que usted utiliza cuando navega en nuestro Sitio Web. Estos archivos de datos permiten intercambiar información de estado entre nuestro Sitio Web y el navegador que usted utiliza. La "información de estado" puede revelar medios de identificación de sesión, medios de autenticación o sus preferencias como usuario, así como cualquier otro dato almacenado por el navegador respecto del Sitio Web.<br />
Las cookies nos permiten monitorear el comportamiento de un usuario en línea. Utilizamos la información que es obtenida a través de cookies para ayudarnos a optimizar configuraciones del Sitio Web y así mejorar su experiencia como usuario (v.g. identificar desde que país ingresa y re-direccionarlo al sitio correspondiente). A través del uso de cookies podemos, por ejemplo, personalizar en su favor nuestra página de inicio de manera que nuestras pantallas se desplieguen de mejor manera de acuerdo a su tipo de navegador.<br />
Como la mayoría de los sitios web, nuestros servidores registran su dirección IP, la dirección URL desde la que accedió a nuestro Sitio web, el tipo de navegador, y la fecha y hora en que realiza actividad. Utilizamos esta información para la administración del sistema y optimización de su uso del Sitio Web.<br />
Su navegador aceptará las cookies y permitirá la recolección automática de información a menos que usted cambie la configuración predeterminada del navegador. La mayoría de navegadores web permiten que usted pueda gestionar sus preferencias de cookies.<br />
Puede ajustar su navegador para que rechace o elimine cookies. Los siguientes links muestran como ajustar la configuración del navegador de los navegadores que son utilizados con más frecuencia:<br />
&emsp;•	Internet Explorer<br />
&emsp;•	Firefox<br />
&emsp;•	Safari<br />
&emsp;•	Chrome<br />
Si se inhabilitan las cookies del Sitio Web, nuestro Sitio no se cargará apropiadamente y podría no habilitar ciertos hipervínculos.<br />
<br />
<b>¿Para que utilizamos sus datos personales?</b><br />
La obtención y tratamiento de sus datos personales se realiza para las siguientes finalidades:<br />
<br />
&emsp;a) Para verificar y registrar su identidad;<br />
&emsp;b) Proveerle el bien y/o servicio que solicite;<br />
&emsp;c) Mantener actualizados nuestros registros a fin de poder responder a sus consultas;<br />
&emsp;d) Para realizar identificación de riesgo en la evaluación, contratación y seguimiento de nuestros servicios:<br />
&emsp;e) Monitoreo y seguridad de las instalaciones, a través de nuestro sistema de video vigilancia en tiendas.<br />
&emsp;f) Para administrar, asesorar, contratar, colocar y operar servicios y productos financieros que solicite;<br />
&emsp;g) Para la realización de nuestros servicios financieros;<br />
&emsp;h) Para notificarlo en caso de cualquier cambio en contrataciones contractuales;<br />
&emsp;i) Para la atención de quejas y aclaraciones que puedan surgir;<br />
&emsp;j) Para atender requerimientos legales con autoridades competentes;<br />
&emsp;k) Monitorear, registrar y retener cualquier llamada telefónica o comunicación electrónica que realices con nosotros, para establecer un registro exacto de la información que nos proporcionas, asegurar que tus instrucciones sean seguidas correctamente, asegurar que los niveles de atención al cliente son mantenidos, resolver quejas, disputas o aclaraciones.<br />
&emsp;l) Integrar expedientes y Bases de Datos, y dar tratamiento a los mismos, con el fin de prestar los servicios financieros propios de su objeto y reportar a las autoridades administrativas y jurisdiccionales dicha información cuando así lo establezcan las disposiciones legales vigentes;<br />
&emsp;m) Cumplir con regulaciones, políticas y medidas de seguridad física y protección civil, tales como sistemas de video vigilancia, acceso a instalaciones y áreas restringidas;<br />
&emsp;n) Para fines de auditoria interna o externa o de cualquier requerimiento de autoridad competente;<br />
&emsp;o) Dar cumplimiento a los requerimientos que nos sean presentados por las Sociedades de Información Crediticia (Buró de Crédito);<br />
<br />
<b>¿Utilizamos sus datos para finalidades no necesarias?</b><br />
KKO podrá utilizar sus datos personales para las siguientes finalidades no necesarias:<br />
&emsp;a) Envío de nuestro Newsletter, el cual incluye publicidad y ofertas, cuando usted así lo haya elegido;<br />
&emsp;b) Realizar estudios de mercado, (por ejemplo, refiriéndose a la frecuencia de compras, edad, entre otras) los cuales se realizarán de forma desagregada; y<br />
&emsp;c) Realizar labores de mercadotecnia y prospección comercial relacionados al diseño, desarrollo, análisis, perﬁlamiento, publicidad, promoción, beneﬁcios, descuentos, estudios de mercado y encuestas;<br />
<br />
El suscribirse a nuestros servicios informativos y de publicidad es absolutamente voluntario. Nosotros no requerimos el hacerle llegar publicidad para mantener o conservar la relación jurídica que podríamos tener con usted en un momento determinado; por ello, usted siempre tendrá la opción inicial de no suscribirse a nuestros sistemas de publicidad; asimismo, tendrá en todo momento la posibilidad de elegir dejar de recibir nuestros mensajes comerciales, ofertas y publicidad. Para ello, usted solamente deberá elegir la opción de "deslistarse" misma que  existirá en cada uno de los mensajes que reciba.<br />
<br />
<b>¿Con quién compartimos sus datos personales?</b><br />
KKO puede transferir los datos personales de sus clientes a otras empresas o autoridades para efectos de las finalidades establecidas en este Aviso de Privacidad. Estas transferencias incluyen las siguientes:<br />
&emsp;a. KKO puede remitir todos o parte de los datos personales a proveedores de servicios que nos apoyan en algún proceso. Estos proveedores incluyen: (i)  empresas que tecnología que nos prestan diversos servicios de comunicaciones o infraestructura; (ii) empresas que nos prestan servicios de publicidad o administración de programas de lealtad. En todos estos casos, KKO se asegura de que estos proveedores asuman obligaciones contractuales que permitan que los datos personales de nuestros clientes se encuentren protegidos.<br />
&emsp;b. Asimismo, KKO puede transferir datos personales de sus clientes a autoridades administrativas o judiciales, cuando la transferencia sea necesaria o legamente exigida para la salvaguarda de un interés público, la protección de los derechos de KKO, la protección de derechos de terceros o para la procuración o administración de justicia. Adicionalmente, KKO puede usar o divulgar sus datos personales cuando consideremos, de buena fe, que la legislación aplicable nos permite usar o divulgar sus datos. Lo anterior incluye, por ejemplo, cuando dicha transferencia resulte necesaria para protegerlo a usted, para responder a reclamaciones o para proteger los derechos de propiedad o la seguridad de KKO, de sus afiliadas o los derechos de terceros.<br />
&emsp;c. Asimismo, la información de los clientes podría ser comunicados o transferidos a un tercero, en el contexto de la negociación de una transacción corporativa, incluyendo una fusión o una venta de activos o una adquisición.<br />
En otros casos, cuando lo permitan las leyes aplicables o se cuente con su consentimiento para realizar la transferencia.<br />
En virtud de la naturaleza internacional de las operaciones de KKO, todas las transferencias anteriores pueden tener el carácter nacional o internacional; asimismo, todas son precisas para el mantenimiento o cumplimiento de la relación comercial que KKO tiene con los clientes y, por lo tanto, no requerimos el consentimiento del cliente para realizarlas. KKO no renta ni vende a terceros los datos personales que obtiene de usted.<br />
<br />
Sus datos personales no serán transferidos a terceros distintos a los antes señalados sin su consentimiento. En caso de ser necesaria la transferencia de sus Datos Personales a dichos terceros,  le será proporcionado un formato o documento en el que se le solicite brindar de manera expresa su autorización para la transferencia.<br />
&emsp;d. <b>¿Cuáles son sus derechos?</b><br />
Usted goza en todo momento con el derecho de acceder y consultar la información que tenemos de usted: asimismo, con el derecho de solicitar que dicha información sea rectificada o cancelada; usted puede oponerse a que KKO trate sus datos para fines específicos y/o revocar en cualquier tiempo el consentimiento que nos haya otorgado para el tratamiento de sus datos personales en la medida que la ley lo permita. Para ejercitar dichos derechos o para formular cualquier duda o queja con relación al tratamiento de sus datos personales por favor contacte a nuestro Departamento de Datos Personales (“DDP”). Por favor dirija su solicitud a privacidad@kko.mx, en atención al "Responsable del Departamento de Datos Personales"<br />
Sus solicitudes serán evaluadas en los términos establecidos en las leyes aplicables. Nuestro DDP le comunicará (i) la información que se le solicitará para que se identifique así como los documentos que necesitará enviar junto con su solicitud; (ii) los plazos en los que recibirá una contestación sobre su solicitud; (iii) cómo debe de presentar su solicitud, incluyendo los formularios que puede usar para presentar su solicitud, si los hubiere, y; (iv)  la modalidad o medio en que le entregaremos la información a usted.<br />
<br />
<b>¿Qué opciones tengo para limitar la forma en que KKO utiliza mis datos personales?</b><br />
En general, cuando recabemos datos personales de usted con respecto a una finalidad que consideremos no es necesaria o esencial en el contexto de nuestra relación jurídica con usted, pondremos a su disposición un mecanismo que le permita manifestar su negativa para que tratemos sus datos para ese fin. Este mecanismo estará disponible antes de recabar sus datos y generalmente, será una casilla para que usted manifieste o no su consentimiento para el tratamiento; asimismo, estos mecanismos estarán disponibles en todo momento, y para poder manifestar su negativa, bastará que usted formule su solicitud en términos de la cláusula anterior.<br />
<br />
Así mismo le informamos que contamos con un listado de exclusión denominado “Personas que no desean ser contactadas” para efectos de finalidades secundarias  o no esenciales, en el cual podrá registrarse mediante solicitud por correo electrónico a privacidad@kko.mx o al domicilio de KKO, en un horario de 9:00 a 17:00 horas en atención a la Oficina de Datos Personales.<br />
<br />
Si usted desea saber acerca de otras opciones que pudieran estar disponibles para que usted pueda  limitar el uso o divulgación que hacemos de sus datos personales, por favor envíenos un correo electrónico a privacidad@kko.mx o contacte nuestra Oficina de Datos Personales.<br />
<br />
<b>Medidas de Seguridad.</b><br />
KKO ha adoptado medidas de seguridad  físicas, organizacionales, y técnicas para proteger sus datos personales (incluyendo los datos personales sensibles), contra daño, pérdida, alteración, destrucción o el uso, accesos o tratamiento no autorizado así como para mantenerlos de un modo correcto y actualizado para los fines que fueron recabados.<br />
<br />
<b>Modificaciones al Aviso de Privacidad.</b><br />
KKO se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad. El aviso modificado será publicado en lugares visibles en nuestras tiendas y/o en nuestra página web o se le hará llegar a través de correo electrónico. Nuestros clientes podrán verificar que el Aviso ha sido modificado en virtud de que haremos notar siempre la fecha de última actualización. En caso de KKO realice algún cambio sustancial en el tratamiento de sus datos personales, se lo haremos saber a través de una notificación a su correo electrónico o anunciando estos cambios en nuestro Sitio Web. Todo cambio realizado a nuestro aviso de privacidad entrará en vigor al siguiente día de que sea publicada la nueva versión nuestro sitio web. Si usted no está de acuerdo con las modificaciones, por favor contáctenos al correo privacidad@kko.mx. El uso continuo que usted haga de los Sitios de Internet seguido de un cambio al Aviso de Privacidad representa consentimiento al nuevo Aviso de Privacidad hasta el límite permitido por la Ley. KKO lo invita a revisar periódicamente nuestro Aviso de Privacidad.<br />
<br />
<b>Datos de Contacto a la Oficina de Protección de Datos Personales.</b><br />
Si usted tiene cualquier pregunta, comentario o queja respecto al presente Aviso de Privacidad, por favor envíe un correo electrónico a la dirección privacidad@kko.mx o escriba a:<br />
KKO<br />
<br />
Jesus García 1542, Col. Villaseñor, 44600, Guadalajara, Jalisco
        </p>
    </StyledSlide>
  </Layout>
)

export default AvisoPrivacidadPage
